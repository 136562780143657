import React from 'react';
// import { } from 'react-icons/fa';
import {FaFacebookF, FaInstagram, FaYoutube,FaXTwitter} from 'react-icons/fa6';

// function TwitterXLogo() {
//   return (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//       {/* Insert the SVG path data for Twitter X logo here */}
//     </svg>
//   );
// }

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-top">
          <div className="footer-logo">
            <a href="/">
              {/* <img src="/images/cmd-primary-darkbg.png" alt="CannabisMD TeleMed" /> */}
              {/* <img src="/images/footermontserrat.png" alt="CannabisMD TeleMed" /> */}
              <img src="/images/footerroboto.png" alt="CannabisMD TeleMed" />
            </a>
          </div>
        </div>
        <div className="footer-section locations">
          <div className="location">
            <h3>Richmond Location</h3>
            <p>1230 Alverser Drive, Suite 103<br/>Midlothian, VA 23113</p>
            <p><a href="tel:8048935068">Office: (804) 893-5068</a><br/>Fax: (804) 728-0546</p>
          </div>
          <div className="location">
            <h3>Fairfax Location</h3>
            <p>10304 Eaton Place, Suite 100<br/>Fairfax, VA 22030</p>
            <p><a href="tel:7039964803">Office: (703) 996-4803</a><br/>Fax: (804) 728-0546</p>
          </div>
          <div className="location">
            <h3>Norfolk Location</h3>
            <p>500 E Main St, FL 16<br/>Norfolk, VA 23510</p>
            <p><a href="tel:7577020116">Office: (757) 702-0116</a><br/>Fax: (804) 728-0546</p>
          </div>
          <div className="location">
            <h3>Arlington Location</h3>
            <p>2300 Wilson Blvd, Suite 700<br/>Arlington, VA 22201</p>
            <p><a href="tel:5712620804">Office: (571) 262-0804</a><br/>Fax: (804) 728-0546</p>
          </div>
        </div>
        <div className="footer-bottom">
          <nav className="footer-nav">
            <a href="/virginia-dispensary-locations">Dispensaries</a>
            <a href="/about">About Us</a>
            <a href="/faq">FAQ</a>
            <a href="/contact-us">Contact Us</a>
            
          </nav>
          
          <div className="social-links">
          <a href="https://twitter.com/cannabismdtele"><FaXTwitter /></a>
            <a href="https://www.facebook.com/CannabisMd-TeleMed-106814338051817"><FaFacebookF /></a>
            <a href="https://www.instagram.com/cannabismd_telemed/"><FaInstagram /></a>
            <a href="https://www.youtube.com/channel/UCP3iMkr6dJm2pZWkPvLQi7A"><FaYoutube /></a>
          </div>
          <a className="ctabtn" href="https://patient.medicalcardonlineva.com/register">Book An Appointment</a>
          <a href="https://www.bbb.org/us/va/midlothian/profile/medical-marijuana-card/cannabismd-telemed-0603-63414856/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-richmond.bbb.org/seals/darkgray-seal-200-65-bbb-63414856.png"  alt="CannabisMD TeleMed BBB Business Review" /></a> 
         
        </div>
        <div className="footer-terms">
          <div className="copy-right">
            <p>© {new Date().getFullYear()} CannabisMD TeleMed, LLC</p>
            <p>Note: Information on this site does not constitute medical advice or legal advice.</p>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
          
        
      </div>
        
    </footer>

  );
}

export default Footer;
