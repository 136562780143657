// FindDoctorSection.js
import React from 'react';
import './css/FindDoctorSection.css';

const FindDoctorSection = ({ className }) => {
  return (
    <div className="find-doctor-section">
      <div className="left-column">
        <div className="image-container">
          <img src="/images/Doctor_with_medical_marijuana.jpg" alt="Doctor with medical marijuana" className="background-image" />
          <div className="overlay">
            <h1 className="headline">How to Find a Registered and Licensed Medical Marijuana Doctor in Virginia</h1>
          </div>
        </div>
      </div>
      <div className="right-column">
        <div className="content">
          <div className="scrollable-content">
            <p>The marijuana doctor and any other marijuana practitioner that use our online platform is registered with the Virginia Board of Pharmacy and licensed with the Virginia Board of Medicine to issue written recommendations for medical marijuana. CannabisMD TeleMed encourages medical professionals to learn more about medical marijuana and how it can be used to effectively treat a variety of conditions. All of the cannabis professionals on our platform believe strongly in the natural healing properties of medical marijuana.</p>
            <div className='button-container'><a href="https://patient.medicalcardonlineva.com/register" className="cta-button">Schedule Your Appointment Today and See a Virginia Medical Marijuana Doctor Online!</a></div>
            <p>For patients looking to legally buy medical cannabis in Virginia, they must first receive a medical marijuana certificate from a marijuana doctor. To get certified by a physician, patients need to <a href="https://patient.medicalcardonlineva.com/register" className="text-link">schedule a virtual marijuana evaluation appointment</a>. During your telemedicine appointment, a marijuana doctor will go over the benefits of medical marijuana and how cannabis can be used to treat your medical condition. The doctors on our platform strongly believe in the natural benefits of medical cannabis.</p>
            <p>Many patients say it was challenging to find a doctor that would recommend them as a qualifying patient for medical marijuana in Virginia. The reason this happens is because many doctors are affiliated with medical associations that specifically do not allow them to issue medical marijuana certifications. Other times doctors do not feel they are knowledgeable enough about the endocannabinoid system and how medical marijuana can be used to naturally and effectively treat their patient’s medical conditions. If you read <a href="https://cannabismdtelemed.com/reviews" className="text-link">the reviews</a>, patients regularly talk about how professional, caring, helpful, and compassionate our team is at CannabisMD TeleMed.</p>
            <p>MMJ doctors at CannabisMD TeleMed believe in the natural benefits of marijuana and how it can promote healing and alleviate symptoms for a wide range of medical conditions. Our support team is available <strong>seven days a week</strong> to answer any questions and assist patients in navigating the process of obtaining medical marijuana in a smooth and stress-free way. When you join the CannabisMD TeleMed family, you receive follow-up support at no additional cost should you have any questions about your marijuana treatment.</p>
            <p>Once certified by a marijuana doctor, you will be able to register for your Virginia medical marijuana card online with the <a href="https://www.virginia.gov/board-of-pharmacy" className="text-link">Virginia Board of Pharmacy</a>. <strong>UPDATE: Starting July 1, 2022, you can immediately begin purchasing medical marijuana products after you are approved by the doctor.</strong></p>
            <div className='button-container'><a href="https://patient.medicalcardonlineva.com/register" className="cta-button">Get Approved Today</a></div>
            <p>All of your information, including your marijuana doctor recommendation, can be submitted online from the comfort and safety of your home. Once the state of Virginia processes your marijuana card application, you will receive the card by mail or email. The default delivery method is email, but if you choose, you can receive a physical copy of your Virginia medical marijuana card mailed directly to your home.</p>
            <p>While waiting for your Virginia medical marijuana card to be processed by the Virginia Board of Pharmacy, we recommend you take this time to familiarize yourself with the locations of the different licensed marijuana dispensaries in Virginia to be ready to visit them once you receive your card!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindDoctorSection;