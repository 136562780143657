import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './css/HeroComponent.css'

const states = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'District of Columbia', label: 'District of Columbia' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'Washington, DC', label: 'Washington, DC' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' }
].sort((a, b) => a.label.localeCompare(b.label));

const HeroSection = () => {
  const [selectedState, setSelectedState] = useState(null);
  const baseUrl = 'https://patient.medicalcardonlineva.com/register';

  useEffect(() => {
    const detectUserState = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        
        // Find the matching state in our states array
        const userState = states.find(
          state => state.label.toLowerCase() === data.region.toLowerCase()
        );
        
        if (userState) {
          setSelectedState(userState);
        }
      } catch (error) {
        console.error('Error detecting user state:', error);
        // Silently fail - don't impact user experience if geolocation fails
      }
    };

    // Run the detection after a short delay to prioritize initial render
    setTimeout(detectUserState, 100);
  }, []);

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const getButtonUrl = () => {
    if (selectedState) {
      // Special case for DC
      if (selectedState.value === 'District of Columbia' || 
          selectedState.value === 'Washington, DC' ||
          selectedState.value === 'Washington DC') {
        return `${baseUrl}/form?state=${encodeURIComponent('Washington, D.C.')}`;
      }
      
      // For all other states, use the original value
      return `${baseUrl}/form?state=${encodeURIComponent(selectedState.value)}`;
    }
    return `${baseUrl}/form`;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '200px',
      backgroundColor: '#fff',
      border: state.isFocused ? '1px solid #08f784' : '1px solid #384e68',
      borderRadius: '20px',
      boxShadow: state.isFocused ? '0 0 5px rgba(8, 247, 132, 0.5)' : 'none',
      '&:hover': {
        borderColor: '#08f784',
      },
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#384e68',
      textTransform: 'uppercase',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#384e68',
      '&:hover': {
        color: '#08f784',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '1px solid #384e68',
      borderRadius: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginTop: '5px',
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0 0 15px 0',
      borderRadius: '20px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px 20px',
      color: '#384e68',
      backgroundColor: state.isSelected ? '#4ac9f3' : '#fff',
      '&:hover': {
        backgroundColor: state.isSelected ? '#4ac9f3' : '#f0f0f0',
      },
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#384e68',
      textTransform: 'uppercase',
    }),
  };

  return (
    <div className="hero-section">
      <div className="green-cloud"></div>
      <div className="hero-content">
        <h1 className="welcome-text">WELCOME</h1>
        <h2 className="main-heading">Get Your Medical Marijuana Card Online In Minutes.</h2>
        <div className="state-selection">
          <Select 
            options={states} 
            styles={customStyles} 
            className="state-dropdown" 
            placeholder="Select State"
            onChange={handleStateChange}
            value={selectedState}
          />
          <a href={getButtonUrl()}>
            <button className="get-card-button">Get Your Card</button>
          </a>
        </div>
        <div className="hero-benefits">
          <div className="hero-benefit-item">
            <img className="check-icon" src="/images/tick.png" alt="icon-1" />
            Affordable And Simple Pricing. Get Approved Or Your Money Back.
          </div>
          <div className="hero-benefit-item">
            <img className="check-icon" src="/images/tick.png" alt="icon-1" />
            Our Platform Connects You To A Network Of Doctors To Get Certified In Minutes.
          </div>
        </div>
      </div>
      <div className="hero-image">
        <img src={'/images/banner-hand.png'} alt="Medical Card" />
      </div>
    </div>
  );
};

export default HeroSection;