// FAQ.js
import React, { useState } from 'react';
import './css/FAQ.css';

export const FAQData = [
    { 
      question: "Is medical marijuana legal in Virginia?", 
      answer: 
        <>
          <p>Yes, medical marijuana is legal in Virginia! Licensed health professionals that are registered with the Board of Pharmacy and licensed with the Board of Medicine can issue written medical marijuana certifications. Patients can become certified for medical marijuana by booking an appointment with a Virginia marijuana doctor today.</p>
          <p>Once a patient receives their VA medical marijuana certification, they can visit any medical marijuana dispensary in Virginia. If you plan on visiting dispensaries in other states we recommend you also apply for your state-issued medical marijuana card following your appointment. The licensed dispensaries are always adding new products to help patients treat any number of medical conditions. The products available range from marijuana flower products, vapes, tinctures, edibles, ointments, concentrates, lotions, suppositories, and more.</p>
        </>, 
      tags: ["home", "howtoqualify"]},
    { 
      question: "Who is eligible to participate in Virginia's medical marijuana program?", 
      answer: "To participate in Virginia's medical marijuana program, individuals must be residents of the state and have a written certification from a registered healthcare provider for a qualifying medical condition as outlined by the state's law.", 
      tags: ["home"]},
    { 
      question: "How can I register as a medical marijuana patient in Virginia?", 
      answer: "To become a registered medical marijuana patient in Virginia, you need to be evaluated and receive your medical marijuana certificate from a licensed healthcare provider. You no longer need to submit an application with the state so once you receive your medical cannabis certificate after your appointment you can go straight to the dispensary.", 
      tags: ["home"]},
    { 
      question: "What medical conditions qualify for Virginia's medical marijuana program?", 
      answer: "Virginia's law does not outline specific qualifying conditions, so any condition the provider feels could benefit from medical cannabis could qualify a patient for medical marijuana. Some common conditions include epilepsy, cancer, multiple sclerosis, anxiety, insomnia, depression, PTSD, and severe pain. For more information you can visit the Virginia Department of Health's official website.", 
      tags: ["home"]},
    { 
      question: "Where can I obtain medical marijuana products in Virginia?", 
      answer: "Registered medical marijuana patients can purchase products from licensed dispensaries across the state. Dispensaries offer a variety of medical marijuana products, including  vape cartridges, flower, gummies, edibles, live rosin, oils, tinctures, capsules, and patches.", 
      tags: ["home"]},
    { 
      question: "Can I grow my own medical marijuana in Virginia?", 
      answer: "No, the cultivation of medical marijuana for personal use is not allowed in Virginia. All medical marijuana must be obtained from licensed dispensaries by registered patients who have received a written certification from a qualified healthcare provider.", 
      tags: ["home"]},
    { 
      question: "Are there limitations on the amount of medical marijuana a patient can possess in Virginia?", 
      answer: "Yes, registered medical marijuana patients in Virginia are allowed to possess a 90-day supply of medical marijuana products. The specific quantity is determined by the patient's healthcare provider and should not be exceeded. Possession beyond the designated limit may lead to legal consequences.", 
      tags: ["home"]},
    { 
      question: "What if I Do Not Live Near a Cannabis Dispensary?", 
      answer: "No problem! Many of the operational dispensaries in Virginia also have delivery options for their marijuana products. They will allow patients to select marijuana products from their online menu and have them delivered right to their door. This means that you can see a doctor, get certified for medical marijuana, receive your marijuana card, and even receive your cannabis products without ever leaving the safety of your home.", 
      tags: ["howtoqualify"]},
    { 
      question: "How to Buy at Virginia’s Medical Marijuana Dispensaries", 
      answer: 
        <>
          <p>Once you have submitted your information to the Board of Pharmacy, they will begin processing your application. Once your application has been processed, you will receive your Virginia medical marijuana card electronically by email. This online application and email option for receiving your Virginia medical cannabis card speed up the process, which allows you to shop at the legal medical marijuana dispensaries in Virginia quicker than before!</p>
          <p>If you need assistance in locating the licensed medical marijuana dispensary that is closest to you, then give us a call at <a href="tel:+18048935068">(804) 893-5068</a>, and our team will assist you in any way they can.</p>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: "What Should I Expect at Your Online Medical Marijuana Evaluation Appointment", 
      answer: 
        <>
          <p>Many patients are nervous when it comes to getting their Virginia medical marijuana card, but if you read our reviews, you will see how easy we make the process. The <a href="/about">marijuana doctors</a> on our platform are compassionate, competent, and truly care about helping you get the treatment you need without any stress or headache. During your appointment, you will meet with a cannabis doctor or marijuana practitioner virtually using your phone or computer. At the appointment, the doctor will go over your medical history to better understand why you are seeking medical marijuana. If the Virginia medical marijuana doctor feels that you could benefit from medical cannabis, then they will immediately email you a written and signed medical marijuana certification, which you can use to register with the Virginia Board of Pharmacy so you can begin visiting <a href="/virginia-dispensaries">licensed dispensaries in VA.</a></p>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: "Am I Guaranteed to Get Approved for Medical Marijuana?", 
      answer: "At CannabisMD TeleMed, we cannot guarantee medical marijuana approval for anyone, as the doctor is the only one who can determine whether you are a qualifying patient or not. However, since Virginia has no list of qualifying conditions, it is likely your condition could be eligible for medical marijuana treatment. If you do not receive your medical cannabis recommendation for any reason, you will receive a 100% refund.", 
      tags: ["howtoqualify"]},
    { 
      question: "What is a Virginia Medical Marijuana Card?", 
      answer: 
        <>
          <p>A Virginia medical marijuana card is a form of identification that shows you have registered with the Virginia Board of Pharmacy to treat your medical condition with medical marijuana. This card is required to access the licensed marijuana dispensaries in Virginia.</p>
          <p>In order to receive many of the legal protections regarding marijuana use in Virginia, you are required to have a Virginia medical marijuana card. This includes the employee protections that come from new medical marijuana legislation that started on July 1, 2021.</p>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: `What is the Best Place to Get a Medical Marijuana Card in Virginia in ${new Date().getFullYear()}`, 
      answer: 
        <>
          <p>CannabisMD TeleMed is the best place to get certified by a medical marijuana doctor in Virginia in {new Date().getFullYear()}. Our medical marijuana platform has the most streamlined process, offers the most affordable pricing, provides physician letters at no additional cost, and our entire staff genuinely cares about helping the residents of Virginia receive the natural treatment of medical marijuana in a safe and affordable way.</p>
          <p>We have 5-star reviews across multiple different platforms because patients believe we are making a significant difference in their lives. Read some of the reviews we have on <a href="https://g.page/r/CZOSmM47Id3QEAE">Google</a>, <a href="https://www.bbb.org/us/va/midlothian/profile/medical-marijuana-card/cannabismd-telemed-0603-63414856">The Better Business Bureau</a>, and <a href="https://www.leafly.com/doctors/cannabismd-telemed--">Leafly</a> so you can better understand our company and what patients are saying about us.</p>
          <p>If you believe you have a medical condition that could benefit from medical marijuana, then look no further. <a href="https://patient.medicalcardonlineva.com/register">Schedule your appointment today</a> and see a licensed marijuana doctor so you can see if you qualify for a Virginia medical marijuana card. All appointments are 100% refundable if you do not receive your medical marijuana recommendation from a licensed cannabis doctor in VA.</p>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: "What Information and Forms Do I Need for a Medical Marijuana Card in Virginia?", 
      answer: 
        <>
          <p>Currently, the Virginia Board of Pharmacy has the following requirements for receiving a Medical Marijuana Card in VA.</p>
          <ul>
            <li><p>A completed medical marijuana application form</p></li>
            <li><p>A completed and signed medical marijuana certification signed by the physician</p></li>
            <li><p>An unexpired state ID or driver’s license (If using an out-of-state ID, or driver’s license or if your address does not match your ID then you will also need to submit proof of current residence such as a utility bill, bank statement, or other official mail)</p></li>
          </ul>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: "How Do I Get Approved for a Virginia Medical Marijuana Card (MMJ Card)?", 
      answer: 
        <>
          <p>If you believe you have a <a>medical condition</a> that could benefit from medical marijuana, then your first step is <a href="https://patient.medicalcardonlineva.com/register">scheduling an appointment</a> with a marijuana doctor so he can determine if medical cannabis is appropriate for you. The marijuana physician’s goal is to help you receive the natural treatment you need and deserve. If the marijuana practitioners are not able to help you, then the appointment is free!</p>
        </>, 
      tags: ["howtoqualify"]},
    { 
      question: "Do I Need Medical Records to Get a Medical Marijuana Certification or Card?", 
      answer: 
        <>
          <p>No, you do not need medical records to be certified by a licensed marijuana practitioners or marijuana doctor. If you do have medical records feel free to send them to us but if you do not have them or they are too difficult to obtain the doctors can establish medical records for you.</p>
          <p>Once you have an virtual appointment online with a marijuana practitioner and receive your medical marijuana certificate you can take your certificate that day to any medical marijuana dispensary location in Virginia to purchase marijuana products. You can also apply with the state of Virginia to get your medical marijuana card if you plan on visiting medical cannabis dispensaries in other states including Washington DC. This process can be done online and usually takes 5 minutes or less. If you run into any problems we are always available to troubleshoot any issues.</p>
          <p>The majority of patients are approved for medical marijuana but if we are unable to approve you for medical marijuana then we immediately refund your money!</p>
          <p>We have a 100% patient satisfaction rating. Just check out our <a href="https://g.page/r/CZYo1FITbMvYEBA">reviews on Google</a></p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "What Conditions Qualify Me to Be Eligible for a Virginia Medical Marijuana Card or Medical Marijuana Certification?", 
      answer: 
        <>
          <p>Any diagnosed condition or disease determined by the clinician to benefit from the use of cannabis can qualify you for medical cannabis. So any medical condition could qualify for medical marijuana treatment.</p>
          <p>Below is a list of some common medical problems that could benefit from medical marijuana treatment.</p>
          <ul>
            <li>Alzheimer’s disease</li>
            <li>Anxiety</li>
            <li>Chronic pain</li>
            <li>Epilepsy</li>
            <li>Fibromyalgia</li>
            <li>Headaches-chronic, migraines</li>
            <li>Insomnia</li>
            <li>Nausea</li>
            <li>Parkinson’s disease</li>
            <li>Rheumatoid Arthritis</li>
            <li>Seizures</li>
            <li>Ulcerative Colitis</li>
            <li>Other medical conditions as determined by the provider</li>
          </ul>
          <p>For a more comprehensive list of conditions that could benefit please <a href="/medical-conditions">Click Here</a>!</p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "Now That Marijuana is Legal Why Should I Get a Medical Marijuana Card?", 
      answer: 
        <>
          <p>New laws that were passed Virginia (House Bill 1862) take effect on July 1, 2021 and give employee protections for the medicinal use of cannabis. In order to receive these employee protections employees must have a valid written certification for the use of cannabis issued by a practitioner!</p>
          <p><strong>Employee protections; medicinal use of cannabis oil.</strong> Prohibits an employer from discharging, disciplining, or discriminating against an employee for such employee's lawful use of cannabis oil pursuant to a valid written certification issued by a practitioner for the treatment or to eliminate the symptoms of the employee's diagnosed condition or disease. The bill provides that such prohibition does not (i) restrict an employer's ability to take any adverse employment action for any work impairment caused by the use of cannabis oil or to prohibit possession during work hours or (ii) require an employer to commit any act that would cause the employer to be in violation of federal law or that would result in the loss of a federal contract or federal funding.</p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "What's Next Once the Provider Certifies Me?", 
      answer: 
        <>
          <p>Submit an application to the Virginia Board of Pharmacy. Our guided <a href="https://www.youtube.com/watch?t=3s&v=Y4xdS3LnBbg">YouTube video</a> shows the application process in its entirety! However, if you need additional assistance we have staff standing by to assist you! <strong>The entire application process usually takes 10 minutes or less!</strong></p>
          <p>Below is what is required to submit the application:</p>
          <ul>
            <li>Your application form (completed electronically)</li>
            <li>Your completed Medical Marijuana Certification <strong>(We supply this part to you by email immediately after your appointment</strong></li>
            <li>Your current state issued driver’s license or state ID (your name and address on your application form must match the name and address on your driver’s license or ID otherwise additional proof of residence will be required such as a utility bill or bank statement)</li>
            <li>a non-refundable $50 state application fee</li>
          </ul>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How do I Find A Medical Marijuana Doctor in Virginia?", 
      answer: 
        <>
          <p>That’s the easy part! Just <a href="https://patient.medicalcardonlineva.com/register">Click Here</a> to schedule a virtual medical marijuana evaluation with a marijuana doctor using your phone, tablet or computer.</p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "Why Should I Get a Virginia Medical Marijuana Card?", 
      answer: 
        <>
          <h3>Effective and Long-Term Natural Treatment ​</h3>
          <p>Many people around the world have been using cannabis for its medicinal qualities for centuries! If you are suffering from a medical condition that you believe could benefit from Medical Marijuana then we strongly suggest you start treatment under the care of a physician that understands the benefits associated with Medical Cannabis. All of the marijuana doctors that use are platform are licensed with the Commonwealth of Virginia to treat patients with Medical Cannabis and both him and his team of physician assistants are always learning more about health benefits of medical cannabis to ensure they stay up-to-date with the latest breakthroughs.</p>
          <h3>Access to Medical Marijuana Dispensaries</h3>
          <p>Your Virginia Medical Marijuana Card gives you access to Medical Cannabis Dispensaries in Virginia and maybe other states! Many states with Medical Marijuana laws allow access to out of state Medical Cannabis Card holders. All Medical Marijuana products should be purchased from a licensed <a href="/virginia-dispensaries">Virginia Medical Cannabis Dispensary</a>. Once you have your Medical Marijuana Card you will be able to purchase Medical Marijuana Products from all Virginia Medical Marijuana Dispensaries. All of the dispensaries will have medical cannabis products such as lollipops, tinctures, oils, sprays, capsules, gels, patches, lozenges, and suppositories. Each product must contain a minimum of 5mg of THC-A and a maximum of 10mg of THC per dose, or single unit.</p>
          <h3>Confidence in Your Natural Medicine</h3>
          <p>As a patient knowing what is in your Medical Cannabis product is very important. When you obtain Medical Cannabis from a licensed Virginia Medical Cannabis dispensary you can have confidence in the quality of what you are receiving. Many patients turn to Medical Cannabis because they want a more natural treatment option for their medical conditions and it is important that they know what they are getting. Once you receive your Medical Cannabis Card ( MMJ Card ) you will have access to all Virginia Medical Cannabis dispensaries!</p>
          <h3>Legal Protection ​</h3>
          <p>By seeing a provider and receiving your Medical Cannabis Card you may receive legal protection! The MMJ Card shows that you are registered with the state and as a result are allowed to possess, transport, and consume medical cannabis products. This is a huge weight lifted off the shoulders’ of many patients. It allows them to freely receive the medical treatment they need and deserve.</p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How Do Patients Receive Medical Marijuana Products?", 
      answer: 
        <>
          <p>Once a patient or caregiver is registered with the Virginia Medical Cannabis program, they can visit any of the five dispensaries located in Virginia. Virginia initially approved five Medical Cannabis Dispensaries in 2017, one dispensary per Health Service Area (HSA). <a href="/virginia-dispensaries">Click Here</a> to see a map of the HSAs and a list of the dispensaries for each Health Service Area. Keep in mind that as a registered patient that possesses a Medical Cannabis Card (MMJ) you are able to visit any of the Virginia Medical Cannabis Dispensaries. Each dispensary grows, cultivates, extracts, dispenses, and delivers the medical cannabis oil all at the same location. The current approved Virginia Medical Cannabis dispensaries are located in Richmond, Portsmouth, Manassass, Bristol, and Staunton. Once you receive your Virginia Medical Cannabis Card you will be able to access these dispensaries.</p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How Much Does it Cost to Register for a Medical Marijuana Card?", 
      answer: "The fee is $50 for each application submitted per patient and $25 for each parent/legal guardian or registered agent. The application fee is nonrefundable and is not included in the medical certification fee. Starting July 1, 2021, patients no longer need to register with the state of Virginia. All they need is the medical cannabis certificate which can be emailed immediately after the appointment with the marijuana doctor.", 
      tags: ["allfaqs"]},
    { 
      question: "What Documentation is Required to Register for a Medical Marijuana Card?", 
      answer: 
        <>
          <ul>
            <li>Completed Certification for the use of Cannabidiol or THC-A <strong>(Can be immediately sent by email by the doctor after your appointment!)</strong></li>
            <li>Proof of Patient’s Residency</li>
            <li>Proof of Patient’s Identity</li>
            <li>Proof of Patient’s Age</li>
            <li>If You Are Under Age 18</li>
              <ul>
                <li>Proof of Parent/Guardian Residency</li>
                <li>Proof of Parent/Guardian Identity</li>
                <li>Proof of Parent/Guardian Age</li>
              </ul>
          </ul>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How do I contact Board of Pharmacy?", 
      answer: 
        <>
          <p><strong>Email: <a href="mailto:pharmbd@dhp.virginia.gov">pharmbd@dhp.virginia.gov</a></strong></p>
          <p><strong>Fax: </strong> (804) 527-4472</p>
          <p><strong>Address: </strong></p>
          <p>Virginia Board of Pharmacy</p>
          <p>Perimeter Center</p>
          <p>9960 Mayland DriveSuite 300</p>
          <p>Henrico, VA 23233-1463 </p>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: `How to get a Medical Card in Virginia ${new Date().getFullYear()}?`, 
      answer: 
        <>
          <p>Patients are able to get medical cards in Virginia so they can purchase medical marijuana from state-regulated medical cannabis dispensaries. At CannabisMD TeleMed we offer convenient virtual online appointments that are conducted using telemedicine with a marijuana practitioner or a marijuana doctor. The entire process for getting a medical marijuana card can be completed entirely online using your phone, tablet, or computer. All you need to do is schedule an appointment with any marijuana provider on our platform at CannabisMD TeleMed by clicking the Book An Appointment button below or giving us a call.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How to get a Cannabis Medical Card in Midlothian Virginia?", 
      answer: 
        <>
          <p>For patients looking to get their medical marijuana card the process is very easy at CannabisMD TeleMed and the entire process can be done online from the comfort of their home. All a patient needs to do is schedule at appointment with a marijuana doctor. The doctors and all of their physician assistants are licensed with the Board of Medicine and registered with the Virginia Board of Pharmacy to issue medical marijuana certificates also know as medical cannabis certificates for Virginia’s medical marijuana and Virginia’s medical cannabis program.</p>
          <p><strong>Patient Steps to Getting a Virginia Medical Marijuana Card.</strong></p>
          <ul>
            <li>Schedule an appointment with a cannabis doctor or one of his practitioners.</li>
            <li>Complete the online intake forms.</li>
            <li>See a licensed and registered cannabis practitioner.</li>
            <li>Register with the Virginia Board of Pharmacy (Entire process can be completed online).</li>
            <li>Get your medical marijuana card in the mail.</li>
            <li>Visit the Virginia state-regulated medical marijuana dispensaries and purchase medicinal marijuana and cannabis products containing CBD and THC.</li>
          </ul>
          <p>Start the online process to receive your medical marijuana card today using telemedicine with a marijuana practitioners by clicking on the Book An Appointment button below.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "How to Apply for a Medical Cannabis Card in Richmond Virginia?", 
      answer:
        <>
          <p>Patients can now get their medical cannabis card entirely online using telemedicine using our online platform to connect with a marijuana doctor or a marijuana practitioner here at CannabisMD TeleMed. The process is streamlined and straight-forward. Just check out our reviews on Google to see what medical marijuana patients are saying about online platform and the doctors.</p>
          <p><strong>CannabisMD TeleMed Online Process to get a Medical Marijuana Card in Richmond Virginia</strong></p>
          <ul>
            <li><a href="https://patient.medicalcardonlineva.com/register">Schedule an appointment</a> online with a practitioner.</li>
            <li>Fill out and submit your online medical marijuana intake forms.</li>
            <li>Use your phone, tablet, or computer to see the doctor or one of his physician assistants and receive your medical cannabis certificate.</li>
            <li>Register with the State of Virginia’s Board of Pharmacy to receive your medical marijuana card.</li>
            <li>Receive your medical marijuana card in the mail within 30 days.</li>
          </ul>
          <p>Book an appointment today so you can apply for a medical cannabis card in Richmond Virginia or anywhere else in Virginia.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>,
      tags: ["allfaqs"]},
    { 
      question: "How to Apply for a Medical Marijuana Certificate in Richmond Virginia?", 
      answer: 
        <>
          <p>Any patient who believes they have any medical conditions that could benefit from medical marijuana can qualify for a medical marijuana certificate as long as they are a resident of the state of Virginia.</p>
          <p>At CannabisMD TeleMed we offer virtual online appointments using telemedicine so patients can get a medical marijuana certificate and apply for their medical marijuana card. We also offer in-office appointments for patients who are less tech savvy at no additional cost. After you see a licensed marijuana doctor or a marijuana practitioner and receive your medical marijuana certificate all patients need to do is complete a simple online application process with the state of Virginia. After they complete this application they typically receive their marijuana card within 30 days in the mail. Since July 1, 2021, patients can immediately visit the dispensary without applying with the state. All they need is their medical marijuana certification which can be emailed immediately after your appointment.</p>
          <p><strong>Marijuana Certificate Process at CannabisMD TeleMed of Richmond Virginia</strong></p>
          <ul>
            <li>Schedule your virtual appointment online at our website.</li>
            <li>Submit your intake forms electronically online using your phone, tablet, or computer.</li>
            <li>See a marijuana practitioner or doctor.</li>
            <li>Submit your medical cannabis card application online with the Virginia Board of Pharmacy.</li>
            <li>Receive your medical marijuana card by mail within 30 days.</li>
            <li>Register with any medical marijuana dispensary in the state of Virginia to purchase medicinal marijuana and cannabis products containing CBD and THC.</li>
          </ul>
          <p>If you feel you have a medical condition that could benefit from medical marijuana please schedule your appointment today by clicking the Book An Appointment button below. Our appointment fee covers follow-up support at no additional cost and comes with a 100% money-back guarantee if you feel that medical marijuana is not right for you at the end of your appointment.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "What Are the Top Health Centers to Get Cannabis Cards in Richmond Virginia?", 
      answer: 
        <>
          <p>The top place to get a medical marijuana card also known as a medical cannabis card is CannabisMD TeleMed. We have many licensed providers standing by including a doctor that is licensed and registered with the Virginia Board of Pharmacy to issue medical marijuana certificates as well as a number of physician assistants that are always learning more about the benefits of medical marijuana.</p>
          <p>We are the most convenient option when it comes to getting a medical marijuana certificate so patients can apply for their medical marijuana card in Virginia. CannabisMD TeleMed offers virtual appointments conducted online using telemedicine which has allowed us to help patients from all over the state and patients never have to leave their home. We have a streamlined process for getting your medical marijuana certificate so you can get your medical marijuana card faster than before and even offer same day appointments.</p>
          <p><strong>Medical Cannabis Card Streamlined Process at CannabisMD TeleMed of Richmond Virginia</strong></p>
          <ul>
            <li>Schedule your online virtual appointment at our website.</li>
            <li>Complete your patient intake forms online from the comfort of your home.</li>
            <li>See a marijuana doctor.</li>
            <li>Receive your medical cannabis certificate.</li>
            <li>Apply for a medical marijuana card with the state of Virginia.</li>
            <li>Receive your card in the mail within 30 days.</li>
          </ul>
          <p>If you are a Virginia resident and have been searching for the cheapest way to get a medical marijuana card then schedule an appointment with us by clicking on the Book An Appointment button below. All appointments come with our 100% money-back guarantee if you feel medical marijuana is not the right treatment for you at the end of your appointment with the marijuana practitioner. <a href="https://g.page/r/CZYo1FITbMvYEBA">Check out our reviews on Google</a> to see what other patients are saying about CannabisMD TeleMed and our process for getting a medical marijuana card.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>, 
      tags: ["allfaqs"]},
    { 
      question: "Virginia Medical Marijuana Card Cost?", 
      answer: 
        <>
          <p>For patients using CannabisMD TeleMed’s online platform, the cost of getting a Virginia Marijuana Card is much cheaper than with other providers. The cost for patients to see a marijuana practitioner or a marijuana doctor to be evaluated for a medical marijuana certificate starts at $85 and can go as high as $99.</p>
          <p>We offer discounts for veterans, first responders, and those who work within the medical cannabis industry. If none of our marijuana certificate evaluation discounts apply to you then your cost will be either $89 or $99 depending on whether you have had a Virginia Marijuana Card in the past.</p>
          <p>The appointment fee comes with a 100% money-back guarantee if you feel medical marijuana is not right for you at the end of your appointment.</p>
          <p>Once you are approved by the marijuana doctor, patients need to complete a simple online application with the state of Virginia to apply for a medical marijuana card. They state of Virginia charges a $50 non-refundable fee to apply for a medical marijuana card. <strong>UPDATE: Starting July 1, 2021, patients are no longer required to register with the state after this appointment! This means you can save $50 and being using your medical cannabis certificate immediately after the appointment with the physician.</strong></p>
          <p>At CannabisMD TeleMed the entire cost for getting a medical marijuana card is $99 or less.</p>
          <p>This cost is lower than what other platforms charge and we offer a more comprehensive and complete process for patients looking to receive their medical marijuana card in Virginia.</p>
          <p><strong>Why Choose CannabisMD TeleMed to Get Your Medical Marijuana Card?</strong></p>
          <ul>
            <li>We offer one-on-one support if you run into any issues submitting your medical cannabis card application with the state of Virginia. <strong>At No Additional Cost!</strong></li>
            <li>We offer follow-up support including letters for your employer explaining your medical need to use cannabis products. <strong>At No Additional Cost!</strong></li>
            <li>We offer discounted prices for previous patients who are looking to renew their medical marijuana certificate.</li>
            <li>We are for Virginians by Virginians! We are a small locally-owned-and-operated company that was started by a father and son looking to bring the natural treatment of medical marijuana to all patients in Virginia who believe they could benefit from medical marijuana.</li>
          </ul>
          <p>If you would like to get your medical marijuana card in Virginia then schedule an appointment with CannabisMD TeleMed by clicking on the Book An Appointment button below.</p>
          <div className='button-wrapper'><a href="https://patient.medicalcardonlineva.com/register"><button>Book an Appointment</button></a></div>
        </>, 
      tags: ["allfaqs"]},
      { 
        question: "How Can I Enter a Dispensary in Virginia?", 
        answer: 
          <>
            <p>Accessing medical marijuana dispensary locations in VA is easier than ever at CannabisMD TeleMed. We have a streamlined process that helps patients see a Virginia marijuana doctor, receive their medical marijuana certificate (also known as a physician recommendation for medical cannabis products). Once you receive your medical marijuana certificate you can immediately visit any medical marijuana dispensary location in Virginia and purchase products all without ever leaving their home.</p>
            <p>In short, all that is needed to buy medical marijuana products at a Virginia cannabis dispensary is your physician recommendation for medical cannabis. If you plan on purchasing medical marijuana products in Washington DC or other states you will need to submit your certificate to the state to receive your Virginia Marijuana Card prior to visiting out-of-state dispensaries.</p>
            <p>If you are interested in shopping at any medical marijuana dispensary in VA, then we recommend you <a href="https://patient.medicalcardonlineva.com/register" className="text-link">schedule your evaluation</a> so we can approve you for medical marijuana treatment today!</p>
          </>, 
      tags: ["vadispense"]},
      { 
        question: "Can I Get Marijuana Without a Medical Card in Virginia?", 
        answer: 
          <>
            <p>With the passage of new marijuana legislation in Virginia in 2021, adult-use marijuana did become legal within VA. This legislation made marijuana use by adults legal in Virginia starting July 1, 2021, but it does not allow the recreational sale of marijuana until sometime in 2024. Many residents of Virginia wonder if marijuana laws will change again and not let recreational sales of marijuana begin at all, especially with new leadership in Virginia.</p>
            <p>None of this affects patients who have a Virginia medical marijuana certification or medical marijuana card because they possess a physician recommendation stating they use marijuana for medical reasons. This recommendation lets patients enter any medical marijuana dispensary location in Virginia and purchase marijuana products. The recommendation also allows patients to receive their state-issued Virginia marijuana card so they can visit dispensaries in other states including Washington DC.</p>
            <p>If you want to buy and possess marijuana flower products, extracts, concentrates, and other cannabis products in VA, then you should get a Virginia marijuana certificate! Then, schedule your appointment now to <strong><a href="https://patient.medicalcardonlineva.com/register" className="text-link">get approved for medical marijuana treatment in Virginia</a></strong> today!</p>
          </>, 
      tags: ["vadispense"]},
      { 
        question: "Virginia Marijuana Dispensary Locations - Which Virginia Dispensary is Closest To Me?", 
        answer: 
          <>
            <p>When Virginia medical marijuana legislation passed and became effective in July of 2020, the Virginia Board of Pharmacy was tasked with establishing a system allowing patients in Virginia to buy cannabis products. Virginia wants to make it convenient for patients to purchase medical marijuana products, so they established five zones, and each zone has a licensed pharmaceutical processor operating within it.</p>
            <p>Virginia’s medical marijuana program took things even a step further to increase access to medicinal marijuana products by allowing cannabis dispensary locations in Virginia to deliver marijuana products directly to a patient’s home. Allowing delivery of medical marijuana to a patient’s home is a massive help for residents of Virginia that do not live near a Virginia medical marijuana dispensary location.</p>
            <p>New cannabis locations are continuing to open throughout Virginia. The marijuana products offered at these dispensary locations are continuously increasing to meet the demand of patients seeking natural treatment options like medical marijuana.</p>
          </>, 
      tags: ["vadispense"]},
      { 
        question: "Can I Get Marijuana Delivered in VA?", 
        answer: 
          <>
            <p>Yes! Once you receive your physician recommendation for medical marijuana from our licensed cannabis doctor, you can order cannabis products online in Virginia and have those medical marijuana products delivered right to your house!</p>
            <p>Get your recommendation from an MMJ doctor in Virginia, so you can begin ordering marijuana products from any licensed marijuana dispensary in Virginia.</p>
            <p>Your appointment can be conducted virtually through telemedicine! <strong><a href="https://patient.medicalcardonlineva.com/register" className="text-link">So Schedule your medical cannabis evaluation today!</a></strong></p>
          </>, 
        tags: ["vadispense"]}
  ];
  
  

  const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const toggleFAQ = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };  
    const filteredFAQData = FAQData.filter(item => item.tags.includes("allfaqs"));

    return (
      <div className="faq-section">
        <h2 className="faq-title"><span class="text-highlight-green">Frequently</span> Asked Questions</h2>
        <div className="faq-items">
          {filteredFAQData.map((item, index) => (
            <div key={index} className="faq-item" onClick={() => toggleFAQ(index)}>
              <div className="faq-question">
                <span>{item.question}</span>
                <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
              </div>
              {activeIndex === index && (
                <div className="faq-answer">
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='section-break'></div>
        <h3 className='detail-info'>For more detailed information specific to medical cannabis go to <a href="https://www.vanorml.org/">https://www.vanorml.org/</a>.</h3>
      </div>
    );
  };
  

export default FAQ;